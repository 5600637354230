<template>
  <div :id="id" :class="customClass"></div>
</template>

<script>
import * as d3 from "d3";
import $ from "jquery";
import { sleep, randomNum, demicalLength } from "@/utils/utils.js";
export default {
  props: ["id", "customClass"],
  data() {
    return {
      svg: null,
      svgLineTotalLength: 0,
      svgLineTotal: 0,
    };
  },
  methods: {
    async playOut() {
      if (this.svg == null) {
        return;
      }
      this.svg.transition().duration(100).style("opacity", "0");
    },
    async init() {
      await this.getSettings();
    },
    getSettings() {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          resolve();
        });
      });
    },
    async barLineChart(
      data,
      {
        curveName = "curveLinear",
        marginTop = 40, // the top margin, in pixels
        marginRight = 50, // the right margin, in pixels
        marginBottom = 30, // the bottom margin, in pixels
        marginLeft = 40, // the left margin, in pixels
        width = 640, // the outer width of the chart, in pixels
        height = 400, // the outer height of the chart, in pixels
        xDomain, // an array of (ordinal) x-values
        xRange = [marginLeft, width - marginRight], // [left, right]
        yDomain, // [ymin, ymax]
        yRange = [height - marginBottom, marginTop], // [bottom, top]
        yFormat = ",f",
        xPadding = 0.3, // 柱子的比例
        duration = 400, //动画持续时长
        delay = 40, //元素之间间隔时长
        ease = "easeBack", //元素之间间隔时长
        barRadius = 0, //柱子的圆角
        valueSuffix = "",
        yTickNumber = 10,
      } = {}
    ) {
      const curve = d3[curveName];
      this.duration = duration;
      console.log(data);

      const X = data.map((d) => d.label);
      const yMax = d3.max([
        d3.max(data, (d) => d.buy),
        d3.max(data, (d) => d.sell),
        d3.max(data, (d) => d.inCome),
      ]);
      const yMin = d3.min([
        d3.min(data, (d) => d.buy),
        d3.min(data, (d) => d.sell),
        d3.min(data, (d) => d.inCome),
      ]);
      const hasNegative = yMin < 0 ? true : false;
      if (hasNegative) yRange = [height - marginBottom - 40, marginTop];

      //domain是数值范围
      //range是画图范围
      if (xDomain === undefined) xDomain = X;
      if (yDomain === undefined) yDomain = [hasNegative ? yMin : 0, yMax];

      const xScale = d3.scaleBand(xDomain, xRange).padding(xPadding);
      const yScale = d3.scaleLinear(yDomain, yRange);
      const yAxis = d3
        .axisLeft(yScale)
        .ticks(yTickNumber, yFormat)
        .tickSizeOuter(0);

      this.xDomain = xDomain;
      this.xScale = xScale;
      this.ease = ease;

      const svg = d3
        .create("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
      this.svg = svg;

      //画title
      const drawAxis = () => {
        const axisY = svg
          .append("g")
          .attr("class", "axis_y")
          .attr("opacity", "0")
          .attr("transform", `translate(${marginLeft},0)`)
          .call(yAxis)
          .call((g) => {
            g.select(".domain").attr("class", "domain").attr("opacity", 0);
            g.selectAll(".tick line")
              .attr("class", "tick_line")
              .attr("opacity", 0);
            g.selectAll(".tick line")
              .clone()
              .attr("x2", width - marginLeft - marginRight)
              .attr("stroke", "rgba(0,0,0,.1)")
              .attr("class", "tick_long_line");
            g.selectAll(".tick text").attr("class", "text");
          });

        axisY
          .selectAll(".domain")
          .transition()
          .duration(400)
          .attr("opacity", 1);
        axisY
          .selectAll(".tick_line")
          .transition()
          .duration(400)
          .attr("opacity", 1);
        axisY
          .selectAll(".tick_long_line")
          .transition()
          .duration(400)
          .attr("opacity", 1);
        axisY.transition().duration(duration).style("opacity", "1");
      };

      drawAxis();

      //画label
      const drawLabel = () => {
        const xAxis = svg
          .append("g")
          .attr("class", "x_axis")
          .selectAll("text")
          .data(data)
          .join("text")
          .attr("x", (d, i) => xScale(X[i]) + xScale.bandwidth() / 2)
          .attr("y", height - marginBottom)
          .attr("dy", "1.3em")
          .text((d, i) => X[i])
          .attr("text-anchor", "middle")
          .attr("class", "x_tick")
          .attr("fill", "currentColor")
          .attr("opacity", 0);

        xAxis
          .transition()
          .delay(function (d, i) {
            return i * delay + duration;
          })
          .duration(duration)
          .ease(d3[ease + "Out"])
          .attr("opacity", 1);
      };
      drawLabel();

      //画柱子
      const drawBar = () => {
        if (hasNegative) {
          svg
            .selectAll(".y_grid")
            .append("line")
            .attr("x1", marginLeft)
            .attr("y1", yScale(0))
            .attr("x2", marginLeft)
            .attr("y2", yScale(0))
            .attr("class", "zero_line")
            .attr("stroke", "#F0F0F0")
            .attr("stroke-width", 1)
            .transition()
            .duration(duration)
            .attr("x2", width - marginRight);
        }

        const Y = data.map((d) => d.inCome);
        const bar = svg
          .append("g")
          .attr("class", "bar_group")
          .selectAll("rect")
          .data(data)
          .join("rect")
          .attr("x", (d, i) => xScale(X[i]))
          .attr("y", (d, i) => (hasNegative ? yScale(0) : yScale(yDomain[0])))
          .attr("rx", barRadius)
          .attr("ry", barRadius)
          .attr("class", (d, i) => "bar")
          .attr("fill", (d, i) => "#178CEA")
          .attr("width", xScale.bandwidth())
          .attr("height", 0);
        //柱子动画
        bar
          .transition()
          .delay(function (d, i) {
            return i * delay;
          })
          .duration(duration)
          .ease(d3[ease + "Out"])
          .attr("y", (d, i) => (Y[i] > 0 ? yScale(Y[i]) : yScale(0)))
          .attr("height", (d, i) => {
            return hasNegative
              ? Math.abs(yScale(0) - yScale(Y[i]))
              : Math.abs(yScale(yDomain[0]) - yScale(Y[i]));
          });
      };
      drawBar();

      //画value
      const drawValue = async () => {
        const Y = data.map((d) => d.inCome);
        const valueGroup = svg
          .append("g")
          .attr("class", "value_group")
          .selectAll("text")
          .data(data)
          .join("text")
          .attr("x", (d, i) => xScale(X[i]) + xScale.bandwidth() / 2)
          .attr("y", (d, i) => (Y[i] > 0 ? yScale(Y[i]) : yScale(Y[i])))
          .attr("dy", (d, i) => (Y[i] > 0 ? -5 : "1.2em"))
          .attr("class", (d, i) => "inCome_value")
          .text((d, i) => Y[i].toFixed(2) + valueSuffix)
          .attr("text-anchor", "middle")
          .attr("fill", (d, i) => "#178CEA")
          .attr("opacity", 0);

        valueGroup
          .transition()
          .delay(function (d, i) {
            return i * delay + duration;
          })
          .duration(duration)
          .ease(d3[ease + "Out"])
          .attr("opacity", 1);
      };
      drawValue();

      //画线
      const drawLine = () => {
        const lineData = [data.map((d) => d.buy), data.map((d) => d.sell)];
        const lineGroup = svg
          .append("g")
          .attr("class", "line_group")
          .attr("transform", "translate(" + xScale.bandwidth() / 2 + ",0)");
        for (let j = 0; j < lineData.length; j++) {
          const Y = lineData[j];
          const pathLine = d3
            .line()
            .curve(curve)
            .x((i) => xScale(X[i]))
            .y((i) => yScale(Y[i]));

          const svgLine = lineGroup
            .append("path")
            .attr("fill", "none")
            .attr("class", `line line${j + 1}`)
            .attr("stroke", "black")
            // .attr("stroke-width", "1")
            .attr("d", pathLine(d3.range(Y.length)));
          const svgLineTotalLength = svgLine.node().getTotalLength();
          // this.svgLineTotalArr[j] = svgLineTotalLength;
          svgLine
            .attr(
              "stroke-dasharray",
              svgLineTotalLength + "," + svgLineTotalLength
            )
            .attr("stroke-dashoffset", svgLineTotalLength)
            .transition()
            .duration(duration)
            // .ease(d3[ease])
            .attr("stroke-dashoffset", 0);
        }
      };
      drawLine();

      $("#" + this.id).html(svg.node());
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="less" scoped>
// @import "./index.less";
</style>
